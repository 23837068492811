<template>
  <div ref="salesRef" :class="['contact-sales', isClientInlinePage ? 'contact-sales_client' : '']">
    <div class="contact-sales-left">
      <div class="title">{{ useTranslateI18n("payment.contactSales.title") }}</div>
      <div class="desc-content">
        <div class="desc-tell">{{ useTranslateI18n("payment.contactSales.desc", [day]) }}</div>
        <div
          class="desc-look"
          v-html="
            useTranslateI18n(`payment.contactSales.desc1`, [
              {
                type: 'static',
                class: `contact-sales_a_go_help`
              },
              {
                text: 'contactus@evoto.ai',
                class: `contact-sales_a_go_email`
              }
            ])
          "
        ></div>
      </div>
      <div class="ev-title">
        <CommonImage :src="getCloudAssets('/images/ev-bg-2.png')" alt="" />
      </div>
    </div>
    <div ref="rightContent" class="contact-sales-right-content">
      <!-- <div class="top-title">{{ useTranslateI18n("payment.contactSales.formTitle") }}</div> -->
      <div class="content-form">
        <ClientOnly>
          <CommonForm
            ref="formRef"
            :form-input-list="formInputList"
            form-label-position="top"
            :form-model="formModel"
            :form-rules="formRules"
            @validate="validateForm"
          />
        </ClientOnly>
      </div>
      <div class="footer">
        <div v-show="footerMaskShow" class="footer-mask"></div>
        <div class="submit" @click="submit">
          <CommonAnimationScrollText>{{ useTranslateI18n("payment.contactSales.submit") }}</CommonAnimationScrollText>
        </div>
        <div
          class="submit-tip"
          v-html="
            useTranslateI18n(`payment.contactSales.policy`, [
              {
                type: 'static',
                class: 'contact-sales_a_go_policy'
              }
            ])
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="tsx">
import { IFormPropsInputList } from "@/types/components/common/form"
import { FormRules } from "element-plus"
import { countriesData } from "@/constant/pages/payment"
import { ICountrySelctModelValue, IContactInfo } from "@/types/components/common/countrySelect"
import { EMAIL_RE } from "@/constant/regex"
import { commonApi, InterfaceStateEnum } from "~~/constant/api/common"
import { getCloudAssets } from "~~/utils"
import CommonForm from "@/components/common/Form.vue"
import CommonAnimationScrollText from "@/components/common/Animation/ScrollText.vue"
import { useUser } from "@/store/user"
import { useTranslateI18n } from "~/composables/store/i18n-country"

let use_user = useUser()

defineProps({
  day: {
    type: [String, Number]
  }
})

const { useCommonMp } = useMp()
const { _pointOfficialContactSalesSumbitButtonClick } = useCommonMp()

const contactImageListDict = {
  common: {
    [IContactInfo.WhatsApp]: getCloudAssets("/images/common/contact-whats.png"),
    [IContactInfo.FaceBook]: getCloudAssets("/images/common/contact-facebook.png"),
    [IContactInfo.Line]: getCloudAssets("/images/common/contact-line.png"),
    [IContactInfo.Zalo]: getCloudAssets("/images/common/contact-zalo.png"),
    [IContactInfo.Kakao]: getCloudAssets("/images/common/contact-talk.png")
  },
  hover: {
    [IContactInfo.WhatsApp]: getCloudAssets("/images/common/contact-whats-hover.png"),
    [IContactInfo.FaceBook]: getCloudAssets("/images/common/contact-facebook-hover.png"),
    [IContactInfo.Line]: getCloudAssets("/images/common/contact-line-hover.png"),
    [IContactInfo.Zalo]: getCloudAssets("/images/common/contact-zalo-hover.png"),
    [IContactInfo.Kakao]: getCloudAssets("/images/common/contact-talk-hover.png")
  }
}

const contactListType = ref([])

const contact_type = ref(IContactInfo.WhatsApp)

const { isClientInlinePage } = useClientPage()

const proxy = useProxy()

const CONSULTATION_SCENE_CODE = 5

const formRef = ref()
const salesRef = ref()
const loadingInstance = ref()
const rightContent = ref()
const footerMaskShow = ref(false)

defineExpose({
  loadingInstance,
  showVerCodeField
})

const validatorEmail = (rule: any, value: any, callback: any) => {
  let regex = EMAIL_RE
  if (!regex.test(value)) {
    return callback(new Error(useTranslateI18n("login.signIn.error")))
  } else {
    return callback()
  }
}

let formModel = reactive({
  first_name: "",
  business_email: use_user.userEmail,
  phone: {
    inputValue: "",
    countryValue: ""
  } as ICountrySelctModelValue,
  monthly_phone_count_id: "",
  content: "",
  verify_code: ""
})
const formInputList = ref<IFormPropsInputList<typeof formModel>>([
  {
    model: "first_name",
    clearable: true,
    label: useTranslateI18n("payment.contactSales.form.fName"),
    noTrim: true,
    type: "input",
    maxlength: 200,
    placeholder: useTranslateI18n("payment.contactSales.form.fNamePlaceholder")
  },
  {
    model: "business_email",
    noTrim: true,
    disabled: !!use_user.userEmail,
    clearable: true,
    label: useTranslateI18n("payment.contactSales.form.bEmail"),
    placeholder: useTranslateI18n("payment.contactSales.form.bEmailPlaceholder"),
    type: "input",
    maxlength: 200
  },
  {
    model: "verify_code",
    type: "verCodeInput",
    label: "Verification Code",
    emailModel: "business_email",
    scene: CONSULTATION_SCENE_CODE,
    showItem: false,
    maxlength: 6
  },
  {
    prefixChildSlots: (item) => {
      const itemHandler = (_i: any) => {
        contact_type.value = _i.value
      }
      return (
        <div class="contact-info-type-wrapper">
          {contactListType.value.map((_i) => {
            return (
              <div
                class={["contact-info-item", contact_type.value == _i.value ? "contact-info-item-hover" : ""]}
                onMouseenter={() => {
                  itemHandler(_i)
                }}
                onClick={() => {
                  itemHandler(_i)
                }}
              >
                <img src={_i.hoverImg} />
              </div>
            )
          })}
        </div>
      )
    },
    label: useTranslateI18n(`payment.contactSales.form.contactInfo`),
    placeholder: useTranslateI18n(`payment.contactSales.form.contactInfoDesc`),
    model: "phone",
    type: "countrySelect",
    showSelect: computed(() => (contact_type.value as unknown as IContactInfo) !== IContactInfo.FaceBook),
    maxlength: 200,
    openCurrentCountry: true
  },
  {
    model: "monthly_phone_count_id",
    type: "select",
    label: useTranslateI18n("payment.contactSales.form.mPhoto"),
    clearable: false,
    placeholder: "  ",
    popperClass: "contact-sales_s",
    extraClass: "monthly-form-item",
    selectData: []
  },
  {
    model: "content",
    label: useTranslateI18n("payment.contactSales.form.tell"),
    clearable: true,
    type: "textarea",
    autosize: true,
    resize: "none",
    maxlength: 1000
  }
])

const { validator: validatorP } = useValidatorInterPhone(false)

let formRules = reactive<FormRules>({
  business_email: [
    {
      required: true,
      trigger: "blur",
      validator: validatorEmail
    }
  ],
  phone: [
    {
      validator: validatorP,
      trigger: "blur"
    }
  ],
  monthly_phone_count_id: [
    {
      required: true,
      trigger: "blur",
      message: useTranslateI18n("payment.contactSales.form.monthly")
    }
  ],
  verify_code: [],
  content: [
    {
      required: true,
      trigger: "blur",
      message: useTranslateI18n("payment.contactSales.form.question")
    }
  ]
})

const emit = defineEmits(["submit", "goHelp"])

const submit = () => {
  _pointOfficialContactSalesSumbitButtonClick({
    preferredPlatform: contactListType.value.find((_) => _.value == contact_type.value)?.label
    // annuallyUsage: formInputList.value
    //   .find((item) => item.model === "monthly_phone_count_id")
    //   .selectData.find((item) => item.value === formModel.monthly_phone_count_id)?.pointLabel
  })
  formRef.value && formRef.value.validate()
}

const validateForm = (v) => {
  if (v) {
    loadingInstance.value = useLoading({
      target: salesRef.value
    })
    emit("submit", { ...formModel, contact_type: contact_type.value })
  }
}

const mailToClick = () => {
  if (process.client) {
    window.location.href = "mailto:contactus@evoto.ai" as string
  }
}

const initData = async () => {
  let photoList = (await getEnumList(2)) || []

  photoList = photoList.filter((item) => (item.id as number) >= 8)
  // let pointerDict = {
  //   1: "1k",
  //   2: "6k",
  //   3: "12k",
  //   4: "60k",
  //   5: "120k",
  //   6: "600k",
  //   7: "1200k"
  // }
  formInputList.value.find((item) => item.model === "monthly_phone_count_id").selectData = photoList.map((item) => {
    return {
      label: item.name,
      value: item.id
      // pointLabel: pointerDict[item.id]
    }
  })

  let contactTypeList = (await getEnumList(18)) || []
  contactListType.value = contactTypeList.map((item) => {
    return {
      label: item.name,
      value: item.id,
      img: contactImageListDict.common[item.id],
      hoverImg: contactImageListDict.hover[item.id]
    }
  })

  await getInterfaceState()

  rightContentScrollFn()
}
initData()

const rightContentScrollFn = () => {
  let timeId = setTimeout(() => {
    nextTick(() => {
      let scrollDom = document.querySelector(".welcome-page-dialog.contact-sales-dialog_1 .content-d") as HTMLElement
      let scrollDomHeight = scrollDom.offsetHeight
      let rightContentWrapperHeight = rightContent.value.offsetHeight
      footerMaskShow.value = !(rightContentWrapperHeight <= scrollDomHeight)
      scrollDom.addEventListener(
        "scroll",
        (e) => {
          let scrollTop = (e.target as HTMLElement).scrollTop
          footerMaskShow.value = !(Math.ceil(scrollTop + scrollDomHeight) >= rightContentWrapperHeight)
        },
        { passive: true }
      )
    })
  })
}

const goHelp = () => {
  emit("goHelp")
}

const goPrivacy = async () => {
  const { getPrivacy } = useGetPolicy()
  let url = await getPrivacy(false)
  if (unref(isClientInlinePage)) {
    const { $bridge } = useNuxtApp()
    // 判断是否是客户端内打开的，需要通知客户端跳外链
    $bridge.ipcRenderer.send("open-external-link", {
      href: `${url}`
    })
  } else {
    useLocationOpen(url)
  }
}

// interface limit
const codeRule = reactive({
  required: true,
  len: 4,
  message: useTranslateI18n("landing.banner.form.verCodeRequireErrMsg")
})
async function getInterfaceState() {
  const state = await checkInterfaceState({
    url: commonApi.CONSULTATION_ADD,
    scene: CONSULTATION_SCENE_CODE
  })

  if (state === InterfaceStateEnum.Limit) {
    formInputList.value.find((item) => item.model === "verify_code").showItem = true
    formRules.verify_code = [codeRule]
  }
}
function showVerCodeField() {
  formInputList.value.find((item) => item.model === "verify_code").showItem = true
  formRules.verify_code = [codeRule]
}

let goHelpDomEvent
let mailDomEvent
let policyDomEvent
onMounted(() => {
  let timeId = setTimeout(() => {
    goHelpDomEvent = useEventListener(document.querySelector(".contact-sales_a_go_help"), "click", goHelp)
    goHelpDomEvent = useEventListener(document.querySelector(".contact-sales_a_go_email"), "click", mailToClick)
    policyDomEvent = useEventListener(document.querySelector(".contact-sales_a_go_policy"), "click", goPrivacy)
    clearTimeout(timeId)
  })
})

onBeforeUnmount(() => {
  goHelpDomEvent && goHelpDomEvent()
  mailDomEvent && mailDomEvent()
  policyDomEvent && policyDomEvent()
})
</script>

<style scoped lang="scss">
.contact-sales {
  display: flex;
  width: 100%;
  .link-text {
    color: #1877f2;
    cursor: pointer;
  }
  &-left {
    width: 398px;
    flex-shrink: 0;
    padding: 36px 35px;
    opacity: 0.7;
    background: #f3f3f3;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    .title {
      @include fontSemibold;
      font-size: 48px;
      line-height: 57px;
    }
    .desc-content {
      margin-top: 24px;
      font-weight: 510;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.55);
      .desc-look {
        margin-top: 16px;
        :deep(.contact-sales_a_go_help) {
          color: #1877f2;
          cursor: pointer;
        }
        :deep(.contact-sales_a_go_email) {
          color: #1877f2;
          cursor: pointer;
        }
      }
    }
    .ev-title {
      display: flex;
      position: absolute;
      bottom: 36px;
      left: 35px;
      :deep(.img-load) {
        width: 87px;
        height: auto;
      }
    }
  }
  &-right-content {
    flex: 1;
    padding: 36px 35px 0;
    .content-form {
      margin-top: 24px;
      margin-bottom: 24px;

      :deep(.pix-common-search) {
        .el-form {
          .el-form-item {
            margin-bottom: 32px;
            .contact-info-type-wrapper {
              display: flex;
              margin-bottom: 12px;
              .contact-info-item {
                margin-right: 8px;
                display: flex;
                cursor: pointer;
                padding: 1px;
                border: 1.5px solid transparent;
                border-radius: 8px;
                transition: all 0.2s;
                img {
                  width: 28px;
                }
                &:nth-last-child(1) {
                  margin-right: 0;
                }
                &.contact-info-item-hover {
                  border: 1.5px solid #ffdf3c;
                }
              }
            }
            label {
              color: #000;
            }
            .el-form-item__label {
              color: #000;
            }
            .el-form-item__content {
              .el-input__wrapper {
                border: none;
                box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset;
                &.is-focus {
                  box-shadow: 0 0 0 2px #ffe050 inset;
                }
                .el-input__inner {
                  height: 55px;
                  font-size: 16px;
                }
              }
              .country-select {
                line-height: 55px;
                height: 55px;
                .el-select {
                  .el-input__wrapper {
                    box-shadow: none;
                    &.is-focus {
                      box-shadow: none !important;
                      border: none !important;
                    }
                  }
                }
                > .el-input {
                  width: 313px;
                }
              }
              @include device-max-width-600 {
                .country-select {
                  > .el-input {
                    width: 100%;
                  }
                }
              }
              .el-select {
                .el-input__wrapper {
                  &.is-focus {
                    box-shadow: 0 0 0 2px #ffe050 inset !important;
                  }
                }
              }
              .el-textarea {
                .el-textarea__inner {
                  height: 108px !important;
                  border: none;
                  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset;
                  &:focus {
                    box-shadow: 0 0 0 2px #ffe050 inset;
                  }
                }
              }
            }
          }

          .el-form-item.monthly-form-item {
            .el-select {
              width: 172px;
            }
            @include device-max-width-600 {
              .el-select {
                width: 100%;
              }
            }
          }
          .el-input__prefix-inner {
            > div {
              margin-right: 4px;
              .select-group_prefix_img {
                display: flex;
                img {
                  width: 26px;
                  height: 26px;
                }
              }
            }
          }

          .el-form-item {
            width: 100%;
            margin-right: 0;
          }
          .el-form-item {
            .el-input__suffix-inner {
              i {
                font-size: 16px;
              }
            }
            .el-select {
              .el-select__caret {
                transform: rotateZ(0);
              }
              .el-input__suffix {
                align-items: center;
                .el-input__suffix-inner {
                  i {
                    width: 24px;
                    height: 24px;
                    background-image: url("@/assets/images/pages/payment/select-icon2.png");
                    background-size: 100% 100%;
                    font-size: 0;
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .footer {
      position: sticky;
      bottom: 0;
      left: 0;
      z-index: 1001;
      background-color: #fff;
      padding-bottom: 40px;
      .footer-mask {
        pointer-events: none;
        width: 460px;
        height: 83px;
        flex-shrink: 0;
        background: linear-gradient(360deg, #fff 14.39%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        top: -83px;
        width: 100%;
      }
      .submit {
        width: 100%;
        background: #ffe050;
        border-radius: 60px;
        height: 42px;
        @include fontSemibold;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .submit-tip {
        margin-top: 8px;
        font-weight: 510;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        :deep(.contact-sales_a_go_policy) {
          color: #1877f2;
          cursor: pointer;
        }
        .policy {
          margin-left: 6px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.contact-sales_s {
  &.el-select-dropdown {
    .el-select-dropdown__item {
      .select-group_imgs {
        width: 100%;
        display: flex;
        align-items: center;
        img {
          width: 26px;
          height: 26px;
          margin-right: 4px;
        }
      }
      &:hover {
        .select-group_imgs {
          color: #fff;
        }
      }
    }
  }
}
.contact-sales.contact-sales_client {
  .desc-look {
    .contact-sales_a_go_help {
      color: rgba(0, 0, 0, 0.55) !important;
    }
  }
}
</style>
