import { SessStorageKey } from "@/constant/cache"
import { ProductType } from "@/composables/api/common"

export const getUSCharAndPrice = (char, price) => {
  return `US` + `${char}${price}`
}

export const getUSChar = (char) => {
  return `US` + `${char}`
}

export const getSplitPkgname = (str: string) => {
  return str.split("-")
}

export enum ICurrency {
  USD = "USD",
  JPY = "JPY",
  KRW = "KRW",
  EUR = "EUR", // 欧元
  GBP = "GBP", // 英镑
  AUD = "AUD", // 澳元
  TWD = "TWD", // 台币
  HKD = "HKD" // 港币
}

export const ICurrencyUnit = {
  [ICurrency.USD]: getUSChar("$"),
  [ICurrency.JPY]: "円",
  [ICurrency.KRW]: "₩",
  [ICurrency.EUR]: "€",
  [ICurrency.GBP]: "£",
  [ICurrency.AUD]: "A$",
  [ICurrency.TWD]: "NT$",
  [ICurrency.HKD]: "HK$"
}

// 集合fb 和 gg 购买上报 ad 转化
export async function useAdsPurchasePoint(value, type: ICurrency = "USD" as ICurrency, orderId?) {
  // const { usePaymentFbq } = useFbq()
  // const { _PointFbqPaymentSuccess } = usePaymentFbq()

  const { usePaymentGtagAu } = useGTagAu()
  const { _PointOfficePaymentAd } = usePaymentGtagAu()

  const { usePaymentTtq } = useTtq()
  const { _PointTtqPaymentSuccess } = usePaymentTtq()

  const { usePaymentSs } = useShareSales()
  const { _pointShareSalesPayment } = usePaymentSs()

  const { usePaymentTwq } = useTwq()
  const { _PointTwqPaymentSuccess } = usePaymentTwq()

  const { usePaymentWcs } = useWcs()
  const { _PointWcsPaymentSuccess } = usePaymentWcs()

  const { usePaymentYtag } = useYtag()
  const { _PointYtagPaymentSuccess } = usePaymentYtag()

  const { usePaymentGtm } = useGtm()
  const { _PointGtmPaymentSuccess } = usePaymentGtm()

  // sharesales 埋点上报
  if (useRoute().query.sscid || getLocalStorage(SessStorageKey.SHARESALES_FLAG, "sessionStorage")) {
    _pointShareSalesPayment(value, type, orderId)
  }

  // fb的成功埋点
  // _PointFbqPaymentSuccess({ value }, type)
  // google ad成功埋点
  _PointOfficePaymentAd(value, type)
  // tt ad成功埋点
  _PointTtqPaymentSuccess(value, type)
  // tw ad成功埋点
  _PointTwqPaymentSuccess(value, type)
  // 韩国naver 成功埋点
  _PointWcsPaymentSuccess(value)
  // 日本yahoo埋点
  _PointYtagPaymentSuccess(value)

  _PointGtmPaymentSuccess(value, type)

  // if (cookiesStore.selections.analytics) {
  //   const country = await getClientCountry()
  //   postToConFb({
  //     data: [
  //       {
  //         event_name: FbqEventName.PaymentPurchase,
  //         event_time: Math.round(new Date().getTime() / 1000),
  //         user_data: {
  //           em: [sha256(use_user.userInfo.email.toLowerCase()).toString()],
  //           country: sha256(country.toLowerCase()).toString(),
  //           fbp: useCookie(`_fbp`).value
  //         },
  //         custom_data: {
  //           currency: type,
  //           value
  //         },
  //         event_source_url: location.href,
  //         action_source: "website"
  //       }
  //     ]
  //   })
  // }
}
